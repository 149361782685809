<template>
  <v-app>
    <v-app-bar app color="white">
    <router-link :to=" { name: 'home' } ">
      <div class="d-flex align-center text-h4 ml-3">
        <v-img width="100" src="@/assets/Logo-Rudinei.png" />
      </div>
    </router-link>
      <v-spacer />
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <div v-for="(item, i) in menu" :key="i">
          <v-hover v-slot="{ hover }">
            <v-btn
              :elevation="hover ? 10 : 0"
              :class="item.color"
              class="mx-2"
              small
              text
              :to="item.link"
              :href="item.clientPage"
              exact
              tile
              >
              {{ item.text }}
              </v-btn>
          </v-hover>
        </div>
      </template>
      <div v-else>
        <v-btn color="black" dark icon @click="mobileMenu = true">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <v-navigation-drawer v-model="mobileMenu" color="amber accent-2" temporary fixed>
      <v-list>
        <v-list-item v-for="(item, index) in menu" :key="index" :to=item.link :href="item.clientPage" link exact>
          <v-list-item-icon>
            <v-icon color="black" class="mx-1">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="black--text font-weight-medium">
            {{ item.text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    mobileMenu: false,
    menu: [
      { text: "Inicio", link: { name: "home" }, icon: "mdi-home-outline" },
      { text: "Empresa", link: { name: "about" }, icon: "mdi-office-building-outline" },
      { text: "Serviços", link: { name: "services" }, icon: "mdi-briefcase-check-outline" },
      { text: "Localização", link: { name: "units" }, icon: "mdi-map-marker-outline" },
      { text: "Links Úteis", link: { name: "links" }, icon: "mdi-link" },
      { text: "Parceiros", link: { name: "partners" }, icon: "mdi-handshake-outline" },
      { text: "Contato", link: { name: "contact" }, icon: "mdi-phone-outline" },
      { text: "Área do Cliente", icon: "mdi-account-outline", clientPage:"https://rudineicontabilidade.app.questorpublico.com.br/entrar", color:"amber darken-1" },
      
    ],
  }),
};
</script>

<style scoped>
.v-btn:hover {
  background-color: #ffb300;
}
.v-btn {
  color: rgb(0, 0, 0) !important;
}
.v-btn--active {
  background-color: #ffb300;
}
.theme--light.v-btn--active::before{
  opacity: 0;
}
.theme--light.v-btn:hover::before{
  opacity: 0;
}
</style>
