import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('@/views/Home.vue'),
        children: [
            { path: '',  name: 'home', },
            { path: 'sobre',  name: 'about', },
            { path: 'servicos',  name: 'services', },
            { path: 'unidades', name: 'units', },
            { path: 'links', name: 'links', },
            { path: 'parceiros', name: 'partners', },
            { path: 'contato',  name: 'contact', },
        ],
    },
    
]

const router = new VueRouter({
    mode: "history",
    routes,
})

export default router
